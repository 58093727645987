/* eslint-disable @typescript-eslint/naming-convention */

import { InjectionToken, Injector } from '@angular/core';
import { CheckboxDefaultRenderingComponent } from './checkbox-default-rendering.component';

export type CheckboxDefaultRenderingComponentType<T = any> = new (...args: any[]) => CheckboxDefaultRenderingComponent<T>;

export const CHECKBOX_DEFAULT_RENDERING_INJECTION_TOKEN = new InjectionToken<any>('CHECKBOX_DEFAULT_RENDERING_INJECTION_TOKEN');

export interface CheckboxRenderingData<T = any> {
  component?: CheckboxDefaultRenderingComponentType<T>;
  /**
   * injected data for the CheckboxComponent only
   * The ConfigBeltComponent, the DropdownComponent and the CheckboxGroupComponent defines data respectively in
   * ConfigBeltSelectOption.data; DropdownItem.data CheckboxItem.data;
   * (Their components will replace this "data" with the "data" of these collection types)
   */
  data?: T;
  /**
   * set by the component
   */
  __renderingInjector?: Injector;
}

export type CheckboxCollectionRenderingData<T = any> = Omit<CheckboxRenderingData<T>, 'data'>;
