
<ng-container *ngIf="!renderingData; else renderingTemplate">
  <label [ngClass]="{'app-checkbox-hide-box': hideBox, 'app-checkbox-empty-label': (!labelless && !label && (innerLabel$ | async) === '')}">
    <ng-container *ngIf="label">
      <div>{{label}}</div>
    </ng-container>
    <ng-container *ngIf="!label && !labelless">
      <div><ng-content></ng-content></div>
    </ng-container>
  </label>
</ng-container>

<ng-template #renderingTemplate>

  <ng-container
    [ngComponentOutlet]="renderingData.component"
    [ngComponentOutletInjector]="renderingData.__renderingInjector"
  ></ng-container>

</ng-template>
