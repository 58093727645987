import { AfterViewChecked, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, EventEmitter, HostBinding, HostListener, Injector, Input, OnDestroy, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UtilCustomCSSPropertyService } from '../../services/util-custom-css-property.service';
import { BaseFormComponent } from '../shared/base-form/base-form.component';
import { CheckboxDefaultRenderingComponent } from './checkbox-renderings/checkbox-default-rendering.component';
import { CheckboxRenderingData, CHECKBOX_DEFAULT_RENDERING_INJECTION_TOKEN } from './checkbox-renderings/checkbox-default-rendering.types';
import { coerceBoolean } from '@util/functions/objects';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends BaseFormComponent implements OnInit, AfterViewChecked, OnDestroy {

  private _checked: boolean = false;
  private _subscription: Subscription;
  private _innerLabelBehaviorSubject = new BehaviorSubject<string>('');

  private _renderingData: CheckboxRenderingData<any>;
  private _labellessBehaviorSubject = new BehaviorSubject<boolean>(false);

  private afterInit = false;

  get innerLabel(): string {
    return this._innerLabelBehaviorSubject.value;
  }

  get innerLabel$(): Observable<string> {
    return this._innerLabelBehaviorSubject.asObservable();
  }

  get labelless(): boolean {
    return this._labellessBehaviorSubject.value;
  }

  @Input()
  set labelless(value: boolean) {
    this._labellessBehaviorSubject.next(coerceBoolean(value));
  }


  @HostBinding('class')
  get classes() {
    const classes = [this.checked ? 'checked' : 'unchecked'];

    if (!this.label || this.labelless) {
      classes.push('labelless');
    }
    if (this.displayAsRadioButton) {
      classes.push('display-as-radio-button');
    }
    if (this.readonly) {
      classes.push('app-checkbox-readonly');
    }
    if (this.disabled) {
      classes.push('app-checkbox-disabled');
    }
    return classes;
  }

  @HostBinding('tabindex')
  get tabindex(): number {
    return (this.hideFromTabIndex || this.disabled) ? -1 : 0;
  }

  @HostBinding('attr.role')
  private role = 'checkbox';

  @HostBinding('attr.aria-checked')
  private get ariaChecked(): string {
    return this.checked + '';
  }

  @Input()
  hideFromTabIndex: boolean;

  @Input()
  displayAsRadioButton: boolean = false;

  @Input()
  set checked(value: boolean) {
    if (typeof value === 'string') {
      value = (value === 'false') ? false : true;
    }
    this._checked = value;
    this.formControl.setValue(value);
    this.checkedChange.emit(this.checked);
    if (this.afterInit) {
      this.checkedChangeAfterInit.emit(this.checked);
    }
    this.afterInit = true;
  }

  get checked(): boolean {
    return this._checked;
  }

  @Input()
  set subscribeTo(value: Observable<any>) {
    this._subscription?.unsubscribe();
    this._subscription = value.subscribe(val => {
      this.checked = !!val;
      this.cdr.markForCheck();
    });
  }

  @Input()
  hideBox: boolean;

  @Input()
  disabled: boolean;

  @Input()
  readonly: boolean;

  @Input()
  name: string;

  @Input()
  set renderingData(value: CheckboxRenderingData<any>) {
    this._renderingData = value;
    if (value && !value.__renderingInjector) {
      value.component = value.component || CheckboxDefaultRenderingComponent;
      value.__renderingInjector = Injector.create({
        name: 'CheckboxRendering',
        parent: this.injector,
        providers: [
          {provide: CHECKBOX_DEFAULT_RENDERING_INJECTION_TOKEN, useValue: value.data},
          {provide: CheckboxComponent, useValue: this}
        ]
      });
    }
  }

  get renderingData(): CheckboxRenderingData<any> {
    return this._renderingData;
  }

  @Output()
  readonly beforeActivate = new EventEmitter<UIEvent>();

  /**
   * will emit the current checked state - perfect for 2-two binding (banana in the box)
   */
  @Output()
  readonly checkedChange = new EventEmitter<boolean>();

  /**
   * will emit a value only after its initial value was set - perfect for binding with functions with further logic
   */
  @Output()
  readonly checkedChangeAfterInit = new EventEmitter<boolean>();

  constructor(
    private elementRef: ElementRef,
    private utilCustomCSSPropertyService: UtilCustomCSSPropertyService,
    private readonly injector: Injector,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override ngOnInit(): void {
    const host = (this.elementRef.nativeElement as HTMLElement);

    this.utilCustomCSSPropertyService.setCSSCustomPropertyForPseudoElements({
      sourcePropertyName: '--cx-color-dark',
      sourceParentElement: host,
      sourceElementCSSSelector: 'label',
      targetPropertyName: '--checkbox-color',
      targetParentElement: host,
      targetElementCSSSelector: 'label'
    });
  }

  /**
   * updating the innerLabel
   */
  ngAfterViewChecked(): void {
    const host = this.elementRef.nativeElement as HTMLElement;
    const innerLabel = host?.innerText || '';

    // if there was not a innerLabel before but there is now, the changeDetector needs to run in the end of the method
    const newlySetInnerLabel = !this._innerLabelBehaviorSubject.value && innerLabel;

    this._innerLabelBehaviorSubject.next(innerLabel);

    if (newlySetInnerLabel) {
      this.cdr.detectChanges();
    }
  }

  override ngOnDestroy(): void {
      this._subscription?.unsubscribe();
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  @HostListener('keydown.space', ['$event'])
  toggle(e: UIEvent) {
    if (!this.disabled && !this.readonly) {

      this.beforeActivate.emit(e);

      // if it is a normal checkbox then it toggles
      if (!this.displayAsRadioButton) {
        this.checked = !this.checked;
      }

      // if it is a radio button than it gets true if it isn't
      if (this.displayAsRadioButton && !this.checked) {
        this.checked = true;
      }

    }
    if (e instanceof KeyboardEvent && e.key === ' ') {
      e.preventDefault();
    }
  }

}
