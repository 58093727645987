/* eslint-disable @typescript-eslint/naming-convention */
import { Component, HostBinding, Injector, Optional } from '@angular/core';
import { CheckboxComponent } from '../checkbox.component';
import { CHECKBOX_DEFAULT_RENDERING_INJECTION_TOKEN } from './checkbox-default-rendering.types';



@Component({
  selector: 'app-checkbox-default-rendering',
  templateUrl: './checkbox-default-rendering.component.html',
  styleUrls: ['./checkbox-default-rendering.component.scss']
})
export class CheckboxDefaultRenderingComponent<T = any> {

  private _data: T;
  private _checkboxComponentRef: CheckboxComponent;

  get injectedData(): T {
    return this._data;
  }

  get checkboxRef(): CheckboxComponent {
    return this._checkboxComponentRef;
  }

  constructor(@Optional() injector: Injector) {
    try {
        this._data = injector.get(CHECKBOX_DEFAULT_RENDERING_INJECTION_TOKEN);
        this._checkboxComponentRef = injector.get(CheckboxComponent);
    } catch (_) {
        // ignore exception
    }
  }

}
